import { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Select, { components } from 'react-select';

import ThemeContext from '../contexts/ThemeContext';

import { getUsergroups } from '../services/usergroupService';
import { setUser } from '../services/userService';
import { showError } from '../services/toastService';
import { logAnalytics } from '../services/analyticsLogger';
import { getUserFromLocalstorage } from '../utils/localStorage';

import i18n from '../i18n';
import { RoundButton, CollapsedTextButton } from './Buttons';
import { StepInput, CheckboxButton } from './Input';
import Spinner from '../components/Spinner';
import Icon from '../components/Icon';
import {
  ContainerStepBox,
  ContentStepBox,
  StepsHeader,
  StepLable,
  FlexRow,
  FlexItem
} from './basicComponents';

import { SELFREGISTRATION_ROUTES, REDIRECT_DELAY } from '../utils/redirectUtils';
import { frequencyPoll } from '../services/authService';
import { v4 as uuid } from 'uuid';

const StyledFlexItem = styled(FlexItem)`
  display: flex;
  margin-top: 16px;
  input {
    margin: 0;
    margin-right: 8px;
  }
  p {
    margin: 0;
    font-size: 12px;
  }
  @media (max-width: 768px) {
    margin-top: 8px;
  }
`;

const StyledFlexRow = styled(FlexRow)`
  flex: 1;
  margin-top: 8px;
`;

const StyledComponentsInput = styled(components.Input)`
  input {
    border-radius: 0;
    color: ${({ color }) => (color)} !important;
    &::-webkit-input-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &::-moz-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &:-ms-input-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
    &:-moz-placeholder {
      color: ${({ lighttextcolor }) => (lighttextcolor)};
    }
  }
`;

const StyledNoOptionsMessage = styled.span`
  padding: 10px;
  display: flex;
  flex: 1;
`;

const MenuItemText = styled.div`
  flex: 1 1 auto;
  display: flex;
  color: #ffffff;
`;

const StyledStepLable = styled(StepLable)`
  margin-bottom: 0;
  margin-left: 9px;
  text-transform: none;
`;

const ButtonsRow = styled(FlexRow)`
  margin-top: 16px;
`;

const SelectCostCenterStep = () => {
  const {
    colors,
    layout,
  } = useContext(ThemeContext);

  const history = useHistory();
  const [user] = useState(getUserFromLocalstorage());
  const [loading, setLoading] = useState(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState({});
  const [polling, setPolling] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);
  const [freeText, setFreeText] = useState('');
  const organizationsWithRefInvicingSearch = ['valery', 'luvn', 'demo'];
  const [isUserGroupUpdated, setIsUserGroupUpdated] = useState(false);

  useEffect(() => {
    if (isUserGroupUpdated) {
      setTimeout(redirectToNextAuthStep, REDIRECT_DELAY);
    }

    return () => clearTimeout(redirectToNextAuthStep, REDIRECT_DELAY);
  }, [isUserGroupUpdated, redirectToNextAuthStep]);

  const redirectToNextAuthStep = useCallback(() => {
    if (isUserGroupUpdated) {
      history.replace(SELFREGISTRATION_ROUTES.password);
    }
  }, [history, isUserGroupUpdated]);

  const getUserGroupPrefix = (userGroupName) => {
    if (typeof userGroupName === 'string') {
      const index = userGroupName.lastIndexOf(', unknow');
      if (index > 0) {
        return userGroupName.substring(0, index);
      }
      const matches = userGroupName.split(',');
      if (matches?.length) {
        return matches[0];
      }
      return userGroupName;
    }
    return null;
  };

  const isRootInvoicingUserGroupsOnly = (search) => {
    if (typeof search === 'string') {
      return organizationsWithRefInvicingSearch.includes(search.toLowerCase());
    }
    return false;
  };

  const getUserGroupData = async (search) => {
    const userGroupPrefix = getUserGroupPrefix(user?.userGroup?.name);
    logAnalytics('Self-registration get user groups', {
      search: search || userGroupPrefix,
      userId: user?.id
    });
    try {
      const res = await getUsergroups(search || userGroupPrefix, true, isRootInvoicingUserGroupsOnly(search || userGroupPrefix));
      const groups = res.groups || res;
      const filteredGroups = groups.filter(group => group.id !== user?.userGroup?.id) ;
      setUserGroups(filteredGroups);
    } catch (err) {
      logAnalytics('Self-registration get user groups error', {
        error: err.message,
        userId: user?.id
      });
    }
  };

  const updateUserHomeGroup = async () => {
    try {
      await setUser(selectedUserGroup.id, true);
    } catch (e) {
      throw new Error('Update user home group failed');
    }
  };

  const sendCustomerComment = async () => {
    await setUser(null, null, freeText);
  };

  const handleNext = async () => {
    setLoading(true);
    try {
      if (selectedUserGroup?.id && (selectedUserGroup?.id !== user.userGroup.id)) {
        logAnalytics('Self-registration update user home group', {
          userGroupId: selectedUserGroup?.id,
          userGroupName: selectedUserGroup?.name,
          userId: user?.id
        });
        await updateUserHomeGroup();
      }
      if (freeText?.length) {
        logAnalytics('Self-registration send not found user group', {
          comment: freeText,
          userId: user?.id
        });
        await sendCustomerComment();
      }
      if (polling) {
        const userFromLocalStorage = getUserFromLocalstorage();
        const body = {
          id: uuid(),
          userId: userFromLocalStorage.id,
          email: userFromLocalStorage.email,
          phone: userFromLocalStorage.phone,
          name: userFromLocalStorage.name,
          answer: polling
        };
        await frequencyPoll(body);

        logAnalytics('Self-registration set polling', {
          polling,
          userId: user?.id
        });
      }
      setLoading(false);
      // nextStep();
      setIsUserGroupUpdated(true);
    } catch (err) {
      setLoading(false);
      logAnalytics('Self-registration update user home group failed', {
        error: err.message,
        userId: user?.id
      });
      showError(i18n('update_user_home_group_failed'));
    }
  };

  useEffect(() => {
    getUserGroupData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isNotFound) {
      setFreeText('');
    } else {
      setSelectedUserGroup({});
    }
  }, [isNotFound]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEnableToContinue = useMemo(() => {
    return polling && (selectedUserGroup?.id || (!selectedUserGroup?.id && freeText));
  }, [selectedUserGroup, freeText, polling]);

  return (
    <ContainerStepBox>
      {loading && (<Spinner overlay={true} />)}
      <ContentStepBox>
        <FlexRow>
          <StepsHeader>{i18n('create_an_account')}</StepsHeader>
        </FlexRow>
        <StepsHeader>
          {i18n('how_often_do_you_predict_to_utilize_interpretation_services')}
          <span> *</span>
        </StepsHeader>
        <StyledFlexRow>
          <FlexItem>
          <StepLable
            data-test='howOftenDoYouPredictToUtilizeInterpretationServices'
          >
          </StepLable>
          <StyledFlexItem>
            <input
              type='radio'
              data-test='daily'
              value={i18n('daily')}
              checked={polling === 'Daily'}
              onChange={() => setPolling('Daily')}
            />
            <p>{i18n('daily')}</p>
          </StyledFlexItem>
          <StyledFlexItem>
            <input
              type='radio'
              data-test='weekly'
              value={i18n('weekly')}
              checked={polling === 'Weekly'}
              onChange={() => setPolling('Weekly')}
            />
            <p>{i18n('weekly')}</p>
          </StyledFlexItem>
          <StyledFlexItem>
            <input
              type='radio'
              data-test='more_than_weekly'
              value={i18n('more_than_weekly')}
              checked={polling === 'More seldom than weekly'}
              onChange={() => setPolling('More seldom than weekly')}
            />
            <p>{i18n('more_than_weekly')}</p>
          </StyledFlexItem>
          <StyledFlexItem>
            <input
              type='radio'
              data-test='periodically'
              value={i18n('periodically')}
              checked={polling === 'Periodically'}
              onChange={() => setPolling('Periodically')}
            />
            <p>{i18n('periodically')}</p>
          </StyledFlexItem>
          </FlexItem>
        </StyledFlexRow>
        <StepsHeader>{i18n('select_the_correct_cost_center_regarding_invoicing')}</StepsHeader>
        <StyledFlexRow>
          <FlexItem>
          <StepLable
            data-test='selectCostCenterStepCostCenterLabel'
          >
            {i18n('cost_center')}
            <span> *</span>
          </StepLable>
          <Select
            value={{
              value: selectedUserGroup,
              label: selectedUserGroup?.name
            }}
            options={userGroups.map((userGroup) => ({
              value: userGroup,
              label: userGroup.name
            }))}
            isDisabled={isNotFound}
            isSearchable={true}
            onChange={(obj) => setSelectedUserGroup(obj.value)}
            styles={{
              control: (styles, { isFocused }) => ({
                ...styles,
                boxShadow: 'none',
                borderColor: isFocused ? colors.webMediumGray : '#cccccc',
                padding: '6px 6px',
                cursor: 'pointer',
                ':hover': {
                  borderColor: colors.webMediumGray
                }
              }),
              option: (base, state) => ({
                ...base,
                cursor: 'pointer',
                transition: layout.transition,
                backgroundColor: state.isSelected ? colors.tulkaMainColor : 'white',
                ':active': {
                  backgroundColor: state.isSelected ? colors.tulkaMainColor : 'white'
                },
                ':hover': {
                  backgroundColor: '#DFFFFE',
                  color: colors.tulkaMainColor
                }
              }),
            }}
            components={{
              Input: (props) => {
                return <StyledComponentsInput
                  color={colors.webGray}
                  lighttextcolor={colors.webMediumGray}
                  {...props}
                />;
              },
              NoOptionsMessage: () => (
                <StyledNoOptionsMessage>
                  {i18n('no_options')}
                </StyledNoOptionsMessage>
              ),
              LoadingMessage: () => (
                <StyledNoOptionsMessage>
                  {i18n('search_message')}
                </StyledNoOptionsMessage>
              ),
              Control: ({ children, ...nestedRest }) => (
                <components.Control {...nestedRest}>
                  <MenuItemText>
                    {children}
                  </MenuItemText>
                </components.Control>
              )
            }}
          />
          </FlexItem>
        </StyledFlexRow>
        <FlexRow>
          <FlexItem>
            <CheckboxButton
              checked={isNotFound}
              handleChange={() => setIsNotFound(!isNotFound)}
              size={22}
            >
              <StyledStepLable>{i18n('i_could_not_find_the_correct_cost_center')}</StyledStepLable>
            </CheckboxButton>
          </FlexItem>
        </FlexRow>
        {isNotFound && (
          <FlexRow>
            <FlexItem>
              <StepLable
                data-test='selectCostCenterStepAreaLabel'
              >
                {i18n('information_regarding_cost_center')}
              </StepLable>
              <StepInput
                type="textarea"
                placeholder={i18n('please_tell_us_where_you_work_in_which_unit_etc')}
                value={freeText}
                onChange={(value) => setFreeText(value)}
                data-test='selectCostCenterStepAreaInput'
              />
            </FlexItem>
          </FlexRow>
        )}
      </ContentStepBox>
      <ButtonsRow>
        <RoundButton
          data-test='selectCostCenterStepNextBtn'
          padding='25'
          onClick={handleNext}
          darkButton={true}
          disabled={!isEnableToContinue}
        >
          <CollapsedTextButton>
            <span>{i18n('continue')}</span>
            <Icon
              icon='keyboard_arrow_right'
              iconLib='material'
              iconSize={30}
              withoutContainer
            />
          </CollapsedTextButton>
        </RoundButton>
      </ButtonsRow>
    </ContainerStepBox>
  );
};

export default SelectCostCenterStep;
