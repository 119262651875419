import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import AppContext from '../contexts/AppContext';

import ChatWizardHeader from './ChatWizardHeader';
import ContentBox from './ContentBox';
import ActionList from './ActionList';
import ActionListItem from './ActionListItem';
import i18n from '../i18n';
import Icon from './Icon';

import {
  isValidCustomerBirthday,
  getValidCustomerBirthday,
  isRequiredCustomFieldsFilled,
  isValidDateInCustomFields
} from '../utils/prebooksHelpers';

const StyledActionBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  ${({ noPadding }) => (noPadding ? '' : 'padding: 10px 40px 10px 40px;')}
  flex: 0 1 auto;
  justify-content: center;
  & + & {
    padding-top: 0 !important;
  }
`;

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const StyledWarningContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 15px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const StyledWarningText = styled.span`
  color: #fcc02e;
`;

export const formatDuration = minutes => {
  if (minutes < 60) {
    return i18n('minutes').replace('{}', minutes);
  } else if (minutes === 60) {
    return i18n('hour').replace('{}', minutes / 60);
  } else if (minutes % 60 === 0) {
    return i18n('hours').replace('{}', minutes / 60);
  } else if (minutes / 60 < 2) {
    return i18n('hour_minutes').replace('{h}', Math.floor(minutes / 60)).replace('{m}', minutes % 60);
  }
  return i18n('hours_minutes').replace('{h}', Math.floor(minutes / 60)).replace('{m}', minutes % 60);
};

const pagePathMap = {
  invoicing: '/billing',
};

const PrebookingSummaryPage = props => {
  const context = useContext(AppContext);

  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [dialectSkill, setDialectSkill] = useState(null);
  const [genderSkill, setGenderSkill] = useState(null);
  const [otherSkill, setOtherSkill] = useState(null);
  const [targetLanguage] = useState((context.user?.selectableLanguages && context.user?.selectableLanguages.length > 0) ? context.user?.selectableLanguages?.find((lang) => lang.name === props.languageName) : []);
  const [selectableSkills] = useState(context.user?.selectableSkills ? context.user?.selectableSkills[targetLanguage?.id] : []);
  const [warningMessages, setWarningMessages] = useState([]);

  useEffect(() => {
    setShowWarningMessage(props.showWarningMessage);
    setWarningMessages(props.warningMessages);
  }, [props.showWarningMessage, props.editMode, props.warningMessages]);

  useEffect(() => {
    props.skills?.forEach((skill) => {
      const foundSkill = selectableSkills?.find((aSkill) => (aSkill?.id === skill?.id));
      if (foundSkill && foundSkill.category.name === 'Dialect') {
        setDialectSkill(foundSkill);
      }
      if (foundSkill && foundSkill.category.name === 'Gender') {
        setGenderSkill(foundSkill);
      }
      if (foundSkill && foundSkill.category.name !== 'Dialect' && foundSkill.category.name !== 'Gender') {
        setOtherSkill(foundSkill);
      }
    });
  }, [props.skills, selectableSkills]);

  const getPresenceTitleKey = (presence, callType) => {
    if (presence === 'remote' || (presence === 'external' && callType !== 'data')) {
      return 'presence_remote';
    }
    if (presence === 'external' && callType === 'data') {
      return 'presence_conference';
    }
    if (presence === 'onsite') {
      return 'presence_on_site';
    }
    if (presence === 'notice') {
      return 'presence_notice';
    }
    return '';
  };

  const isVisible = useCallback((name, value) => {
    if (props.editableFields && props.editableFields.length > 0) {
      return props.editableFields.includes(name);
    }
    return Boolean(value);
  }, [props.editableFields]);

  const getTitle = () => {
    if (props.editMode) {
      return i18n('change_reservation_description');
    }
    if (props.editableFields && props.editableFields.length > 0) {
      return i18n('edit_prebooking_details');
    }
    if (!props.readOnly) {
      return i18n('confirm_prebooking');
    }
    return i18n('prebooking_datails');
  };

  const getHelpId = () => {
    if (!props.readOnly) {
      return 'prebooking_summary';
    }
    return '';
  };

  const getCustomFieldsValuePreview = (customGroupFields, customGroupValues = {}) => {
    return customGroupFields?.map((customField) => {
      if (customField.type === 'date') {
        return customGroupValues[customField.name] ? moment(customGroupValues[customField.name]).format('DD/MM/YYYY') : '';
      }
      return customGroupValues[customField.name];
    })?.filter((value) => value).join(', ');
  };

  const getWarningComponent = (editMode, dataAttrValue) => {
    return (
      <>
        {warningMessages.map(message => (
          <StyledActionBlock>
            <StyledWarningContainer>
              <StyledIcon
                icon='warning_amber'
                iconLib='material'
                iconColor={'#fcc02e'}
                iconSize={18}
              />
              <StyledWarningText data-test={dataAttrValue}>{message}</StyledWarningText>
            </StyledWarningContainer>
          </StyledActionBlock>
        ))}
      </>
    );
  };

  const addressAndArrivalInstructions = useMemo(() => {
    let pcValue = props?.postcode ? `${props.postcode}, ` : '';
    let lcValue = props?.locationCity ? `${props.locationCity}, ` : '';
    let saValue = props?.streetAddress ? `${props.streetAddress}.` : '';
    let mValue = props?.message ? ` ${props.message}.` : '';
    return pcValue + lcValue + saValue + mValue;
  }, [props.postcode, props.locationCity, props.streetAddress, props.message]);

  const getStringValue = (arr) => {
    return arr.filter(elem => elem).map((elem, idx, array) => {
      if (idx === array.length - 1) {
        return elem;
      }
      return `${elem}, `;
    });
  };

  const isFromInvoicingPage = useMemo(() => props?.locationPath?.includes(pagePathMap.invoicing), [props?.locationPath]);

  const additionalInformation = useMemo(() => {
    let emailValue = props?.ccEmails?.length ? getStringValue(props?.ccEmails) : '';
    let additionalStringValue = getStringValue([props?.comment, props?.patientName, props?.subject, emailValue]);
    return additionalStringValue;
  }, [props.comment, props.patientName, props.subject, props.ccEmails]);

  const ccEmail = useMemo(() => {
    let value = getStringValue(props?.ccEmails);
    return value;
  }, [props.ccEmails]);

  const isVisibleAdditionalInfoFields = useCallback((pageName) => {    
    if ( pageName === 'additionalInfo') {
      return Boolean(props.needToShowAdditional);
    }
    if (pageName === 'subject' || pageName === 'patient_name' || (pageName === 'cc_emails' && context.user.type !== 'Interpreter')) {
      if (isFromInvoicingPage) { 
        return false;
      }
      return Boolean(!props.needToShowAdditional);
    }
    return false;
  }, [isFromInvoicingPage, props.needToShowAdditional, context.user.type]);

  const isVisibleAdditionalInvoicingInformation = useCallback(() => {
    const isNotNotice = props.presence !== 'notice';
    const isCustomFieldsExist = !!(props.customGroupFields && props.customGroupFields.length > 0);
    const isAllCustomFieldsEmpty = props.customGroupFields.every(cf => !cf.value);
    const isCloningOrEdit = !!(props.cloning || props.editMode);
    const isCurrentUser = props.customerId === context.user.id;
    const isOndemand = props.prebooking?.chatRoom === undefined;
    
    const isShowFieldInDetails = () => {
      if (!isCloningOrEdit && !isAllCustomFieldsEmpty) {
        return !!props.needToShowCustomerReference;
      } 
      else {
        return isVisible('CustomerReference', props.customGroupFields);
      }
    };

    if (isFromInvoicingPage) {
      if (isOndemand) {
        return true;
      } else {
        return (isNotNotice && isCustomFieldsExist && isShowFieldInDetails() && isCurrentUser);
      }
    } else {
      return !!props.needToShowCustomerReference;
    }

  }, [ props.customerId, context.user, props.presence, props.customGroupFields, props.cloning, props.editMode, props.needToShowCustomerReference, isVisible, isFromInvoicingPage, props.prebooking?.chatRoom]);

  const isRequiredInvoiceUserGroup = useMemo(() => {
    return !props.invoiceUserGroupName;
  }, [props.invoiceUserGroupName]);
  
  useEffect(() => {
  }, [isFromInvoicingPage]);

  const feedbackKeysMap = {
    already_exist: 'feedback_already_exist',
    need_to_leave: 'feedback_need_to_leave',
    chatroom_not_exist: 'chatroom_not_exist'
  };

  const getBackupOption = () => {
    if (!props.presenceBackup && !props.prebooking?.customerServiceComments) {
      return i18n('no_backup_option_selected');
    }

    const presenceBackupMsg = props.presenceBackup === 'remote' 
      ? i18n('use_remote_interpretation_option') : i18n('cancel_prebooking_option');
    const customerServiceCommentsMsg = !props.prebooking?.customerServiceComments 
      ? '' : props.prebooking?.customerServiceComments === 'CANCEL PREBOOKING' 
      ? i18n('cancel_prebooking_option') : i18n('use_remote_interpretation_option');

    return customerServiceCommentsMsg || presenceBackupMsg;
  };

  const isInvoicingPage = useMemo(() => window.location.pathname.includes(pagePathMap.invoicing), []);

  const isBackupOptionVisible = useMemo(() => {
    return !isInvoicingPage && props.presence === 'onsite';
  }, [isInvoicingPage, props.presence]);

  const isFeedbackExist = (feedbacks, user) => feedbacks?.length > 0 && feedbacks?.find((feedback) => feedback.userId === user.id);
  
  const getFeedbackKey = useCallback((feedbacks) => {
    if (isFeedbackExist(feedbacks, context.user)) {
      return feedbackKeysMap.already_exist;
    } else {
      return feedbackKeysMap.need_to_leave;
    }
  }, [feedbackKeysMap.already_exist, feedbackKeysMap.need_to_leave, context.user]);

  const getFeedbackValue = useCallback((prebooking) => {
    if (prebooking?.chatRoom === undefined) {
      return getFeedbackKey(prebooking?.feedbacks);
    } else {
      if (prebooking?.chatRoom?.id) {
        return getFeedbackKey(prebooking?.feedbacks);
      } else {
        return feedbackKeysMap.chatroom_not_exist;
      }
    }
  }, [feedbackKeysMap.chatroom_not_exist, getFeedbackKey]);

  const feedbackValue = useMemo(() => {
    const prebookingFeedbackValue = getFeedbackValue(props.prebooking);
    return i18n(prebookingFeedbackValue);
  }, [props.prebooking, getFeedbackValue]);

  const needToRedirectToFeedback = useMemo(() => getFeedbackValue(props.prebooking) === feedbackKeysMap.need_to_leave, [getFeedbackValue, props.prebooking, feedbackKeysMap.need_to_leave]);

  const historyState = useMemo(() => {
    const initState = { from: pagePathMap.invoicing };
    if (props.prebooking?.chatRoom === undefined) {
      return { ...initState, chatroomId: props.prebooking?.id, prebookingId: null };
    } else {
      return { ...initState, chatroomId: props.prebooking?.chatRoom?.id, prebookingId: props.prebooking?.id };
    }
  }, [props.prebooking?.chatRoom, props.prebooking?.id]);

  const isInvoiceRefRequired = useMemo(() => {
    return props.usergroupInvoiceRef & !props.invoiceRef;
  }, [props.usergroupInvoiceRef, props.invoiceRef]);

  return (
    <StyledContainer>
      <ChatWizardHeader
        title={getTitle()}
        clonePrebook={props.clonePrebook}
        editPrebook={!props.cancelledByUser && props.editPrebook}
        helpId={getHelpId()}
        data-test='changeReservationModalTitle'
      />
      {
        props.readOnly ? (
          <StyledActionBlock noPadding={!(props.callType !== 'data' && props.interpreterPhoneNumber) && !(props.callType === 'data' && props.ongoing)}>
            {
              props.callType !== 'data' && props.interpreterPhoneNumber && !isFromInvoicingPage ? (
                <ActionList
                  flexList
                >
                  <ActionListItem
                    key='to_start_interpretation'
                    data-test='toStartInterpretationPrebookSummary'
                    alignCenter
                    noPaddings
                    readOnly={props.readOnly}
                    title={i18n('to_start_interpretation')}
                    subtitle={props.interpreterPhoneNumber}
                    visible={Boolean(props.interpreterPhoneNumber)}
                  />
                </ActionList>
              ) : null
            }
          </StyledActionBlock>
        ) : null
      }
      {(props.cloning || props.editMode) && showWarningMessage ? getWarningComponent(props.editMode, 'waitingMessage') : null}
      <ContentBox overlapping={true}>
        <ActionList>
          <ActionListItem
            key='prebooking_date'
            data-test='prebookingDatePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('prebooking_date')}
            subtitle={moment(props.date).format('DD/MM/YYYY')}
            visible={isVisible('PrebookingDate', props.date)}
            onClick={() => props.onEditPage('Date', null, 'Summary')}
          />
          <ActionListItem
            key='prebooking_time'
            data-test='prebookingTimePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('prebooking_time')}
            subtitle={moment(props.time).format('HH:mm')}
            visible={isVisible('PrebookingTime', props.time) && !props.isNoticeInterpretation}
            onClick={() => props.onEditPage('Time', null, 'Summary')}
          />
          <ActionListItem
            key='interpreter_name'
            data-test='prebookingInterpreterPrebookSummary'
            editable={false}
            readOnly={true}
            title={i18n('interpreter_name')}
            subtitle={props.interpreterName}
            visible={isVisible('Interpreter', props.interpreterName)}
          />
          <ActionListItem
            key='language_title'
            data-test='languageTitlePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('language_title')}
            subtitle={props.languageName}
            required={(props.cloning || props.editMode) && !props.languageName}
            visible={(props.cloning || props.editMode) || isVisible('LanguageTitle', props.languageName)}
            onClick={() => props.onEditPage('Language', null, 'Summary')}
          />
          <ActionListItem
            key='prebooking_duration'
            data-test='prebookingDurationPrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('prebooking_duration')}
            subtitle={formatDuration(props.duration)}
            visible={isVisible('PrebookingDuration', props.duration) && !props.isNoticeInterpretation}
            onClick={() => props.onEditPage('Duration', null, 'Summary')}
          />
          <ActionListItem
            key='call_type_title'
            data-test='callTypeTitlePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('call_type_title')}
            subtitle={i18n(`call_type_${props.callType === 'data' ? 'video' : 'call'}`)}
            visible={isVisible('CallType', props.callType) && props.presence !== 'notice' && props.presence !== 'onsite' && !(props.presence === 'external' && props.callType === 'data')}
            onClick={() => props.onEditPage('CallType', null, 'Summary')}
          />
          <ActionListItem
            key='target_language_title'
            data-test='targetLanguageTitlePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('target_language_title')}
            subtitle={props.targetLanguageName}
            visible={isVisible('TargetLanguage', props.targetLanguageName) && !props.isNoticeInterpretation}
            onClick={() => props.onEditPage('TargetLanguage', null, 'Summary')}
          />
          <ActionListItem
            key='dialect_title'
            data-test='dialectTitlePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('dialect_title')}
            subtitle={props.dialectSkillName || dialectSkill?.name || i18n('any_dialect')}
            visible={(props.cloning || props.editMode) ? props.needToShowDialectSkill : isVisible('DialectSkillName', props.dialectSkillName || dialectSkill)}
            onClick={() => props.onEditPage('Dialect', null, 'Summary')}
          />
          {genderSkill?.name}
          <ActionListItem
            key='gender_title'
            data-test='genderTitlePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('gender_title')}
            subtitle={props.genderSkillName || genderSkill?.name || i18n('any_gender')}
            visible={(props.cloning || props.editMode) ? props.needToShowGenderSkill : isVisible('GenderSkillName', props.genderSkillName || genderSkill)}
            onClick={() => props.onEditPage('Gender', null, 'Summary')}
          />
          <ActionListItem
            key='skill_title'
            data-test='genderTitlePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('skill_title')}
            subtitle={props.otherSkillName || otherSkill?.name || i18n('any_skill')}
            visible={(props.cloning || props.editMode) ? props.needToShowOtherSkill : isVisible('SkillName', props.skillName || otherSkill)}
            onClick={() => props.onEditPage('Skill', null, 'Summary')}
          />
          <ActionListItem
            key='presence_title'
            data-test='presenceTitlePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('presence_title')}
            subtitle={i18n(getPresenceTitleKey(props.presence, props.callType))}
            visible={isVisible('Presence', props.presence)}
            onClick={() => props.onEditPage('Presence', null, 'Summary')}
          />
          <ActionListItem
            key='interpreter_phone_number'
            data-test='interpreterPhoneNumberPrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('interpreter_phone_number')}
            subtitle={props.interpreterPhoneNumber}
            visible={isVisible('InterpreterPhoneNumber', props.interpreterPhoneNumber)}
          />
          <ActionListItem
            key='interpreter_email'
            data-test='interpreterEmailPrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('interpreter_email')}
            subtitle={props.interpreterEmail}
            visible={isVisible('InterpreterEmail', props.interpreterEmail)}
          />
          {props.presence === 'onsite' && (
            <ActionListItem
              key='addressAndArrivalInstrustions'
              data-test='addressAndArrivalInstrustionsPrebookSummary'
              editable={true}
              readOnly={props.readOnly}
              title={i18n('addressAndArrivalInstrustions')}
              subtitle={addressAndArrivalInstructions}
              visible={isVisible('PrebookingDate', props.date)}
              onClick={() => props.onEditPage('Location', null, 'Summary')}
            />
          )}
          {isBackupOptionVisible && (
            <ActionListItem
              key='backupOption'
              data-test='backupOptionPrebookSummary'
              editable={true}
              readOnly={props.readOnly}
              title={i18n('backup_option')}
              subtitle={getBackupOption()}
              required={props.cloning && !props.presenceBackup ? true : false}
              onClick={() => props.onEditPage('RemoteAsBackup', null, 'Summary')}
            />
          )}
          <ActionListItem
            key='additionalInfo'
            data-test='additionalInfoPrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('additional_order_info')}
            subtitle={additionalInformation}
            visible={isVisibleAdditionalInfoFields('additionalInfo')}
            onClick={() => props.onEditPage('additionalInfo', null, 'Summary')}
          />
          <ActionListItem
            key='subject'
            data-test='subjectPrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('subject')}
            subtitle={props.subject || ''}
            visible={isVisibleAdditionalInfoFields('subject')}
            onClick={() => props.onEditPage('additionalInfo')}
          />
          <ActionListItem
            key='patient_name'
            data-test='patientNamePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('patient_name')}
            subtitle={props.patientName}
            visible={isVisibleAdditionalInfoFields('patient_name')}
            onClick={() => props.onEditPage('additionalInfo')}
          />
          <ActionListItem
            key='cc_emails'
            data-test='patientNamePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('cc_email')}
            subtitle={ccEmail}
            visible={isVisibleAdditionalInfoFields('cc_emails')}
            onClick={() => props.onEditPage('additionalInfo')}
          />
          <ActionListItem
            key='favorite_title'
            data-test='favoriteTitlePrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('favorite_title')}
            subtitle={props.favoriteNames || i18n('any_favorite')}
            visible={(props.cloning || props.editMode) ? props.needToShowFavorite : isVisible('FavoriteNames', props.favoriteNames)}
            onClick={() => props.onEditPage('PreferredInterpreter', null, 'Summary')}
          />
          <ActionListItem
            key='invoice_user_group'
            data-test='invoiceUserGroupPrebookSummary'
            editable={(props.cloning || props.editMode) || (props.editableFields && props.editableFields.includes('InvoiceTargeting'))}
            readOnly={props.editableFields && props.editableFields.includes('InvoiceTargeting') ? false : props.readOnly}
            title={i18n('invoice_user_group')}
            subtitle={props.invoiceUserGroupName || i18n('no_user_group_selected')}
            required={isRequiredInvoiceUserGroup}
            visible={(props.cloning || props.editMode) ? props.needToShowInvoiceTargeting : isVisible('InvoiceTargeting', props.invoiceUserGroupName)}
            onClick={() => props.onEditPage('InvoiceTargeting', null, 'Summary')}
          />
          <ActionListItem
            key='customer'
            data-test='customerPrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('customer')}
            subtitle={props.customerName}
            visible={isVisible('CustomerName', props.customerName) && !props.isNoticeInterpretation}
            onClick={() => {}}
          />
          <ActionListItem
            key='customer_unit'
            data-test='customerUnitPrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={props.readOnly}
            title={i18n('customer_unit')}
            subtitle={props.group}
            visible={isVisible('CustomerUnit', props.group)}
            onClick={() => {}}
          />
          <ActionListItem
            key='customer_reference'
            data-test='customerReferencePrebookSummary'
            editable={(props.cloning || props.editMode) || (props.editableFields && props.editableFields.includes('CustomerReference'))}
            readOnly={props.editableFields && props.editableFields.includes('CustomerReference') ? false : props.readOnly}
            title={i18n('customer_reference')}
            subtitle={getCustomFieldsValuePreview(props.customGroupFields, props.customGroupValues) || ''}
            required={!isRequiredCustomFieldsFilled(props.customGroupFields, props.customGroupValues) || !isValidDateInCustomFields(props.customGroupFields, props.customGroupValues)}
            visible={isVisibleAdditionalInvoicingInformation()}
            onClick={() => props.onEditPage('CustomerReference', 'editCustomGroupFields', 'Summary')}
          />
          <ActionListItem
            key='customerInvoiceNotes'
            data-test='customerInvoiceNotesPrebookSummary'
            editable={(props.cloning || props.editMode) || (props.editableFields && props.editableFields.includes('CustomerReference'))}
            readOnly={props.editableFields && props.editableFields.includes('CustomerReference') ? false : props.readOnly}
            title={i18n('customerInvoiceNotes')}
            subtitle={props.invoiceRef || i18n('no_invoice_reference')}
            required={isInvoiceRefRequired}
            visible={!props.isNoticeInterpretation && ((props.cloning || props.editMode) ? props.needToShowCustomerReference : isVisible('CustomerReference', props.invoiceRef))}
            onClick={() => props.onEditPage('CustomerReference', 'customerInvoiceNotes', 'Summary')}
          />
          {/* Hide feedbackV2 for 1.0.92 release */}
          {/* {isInvoicingPage && (
            <ActionListItem
              key='feedbackV2'
              data-test='feedbackV2'
              editable={false}
              readOnly={!needToRedirectToFeedback}
              title={i18n('feedback')}
              subtitle={feedbackValue}
              visible={true}
              onClick={() => needToRedirectToFeedback && history.replace('/feedback', historyState)}
            />
          )} */}
          <ActionListItem
            key='customer_name_and_date_of_birth'
            data-test='customerNameAndDateOfBirthPrebookSummary'
            editable={(props.cloning || props.editMode) || (props.editableFields && props.editableFields.includes('CustomerInfo'))}
            readOnly={props.editableFields && props.editableFields.includes('CustomerInfo') ? false : props.readOnly}
            title={i18n('customer_name_and_date_of_birth')}
            required={(props.cloning || props.editMode) && !isValidCustomerBirthday(props.customerDataBirthday)}
            subtitle={`${props.customerData} ${getValidCustomerBirthday(props.customerDataBirthday)}`}
            visible={!props.isInterpreterPreview && isVisible('CustomerInfo', props.isNoticeInterpretation)}
            onClick={() => props.onEditPage('CustomerInfo', 'customerNameAndDateOfBirth', 'Summary')}
          />
          <ActionListItem
            key='to_whom_the_message_is_forwarded_if_not_to_the_customer'
            data-test='toWhomTheMessageIsForwardedIfNotToTheCustomerPrebookSummary'
            editable={(props.cloning || props.editMode) || (props.editableFields && props.editableFields.includes('CustomerInfo'))}
            readOnly={props.editableFields && props.editableFields.includes('CustomerInfo') ? false : props.readOnly}
            title={i18n('to_whom_the_message_is_forwarded_if_not_to_the_customer')}
            subtitle={props.alternativeContactPerson || i18n('optional_information')}
            visible={!props.isBillingSummary && isVisible('CustomerInfo', props.isNoticeInterpretation)}
            onClick={() => props.onEditPage('CustomerInfo', 'toWhomTheMessageIsForwardedIfNotToTheCustomer', 'Summary')}
          />
          <ActionListItem
            key='telephone_number_to_which_the_interpreter_will_call'
            data-test='telephoneNumberToWhichTheInterpreterWillCallPrebookSummary'
            editable={(props.cloning || props.editMode) || (props.editableFields && props.editableFields.includes('CustomerInfo'))}
            readOnly={props.editableFields && props.editableFields.includes('CustomerInfo') ? false : props.readOnly}
            title={i18n('telephone_number_to_which_the_interpreter_will_call')}
            required={(props.cloning || props.editMode) && !props.mainPhoneNumber}
            subtitle={`${props.mainPhoneNumber} ${props.phoneNumbers}`}
            visible={!props.isBillingSummary && isVisible('CustomerInfo', props.isNoticeInterpretation)}
            onClick={() => props.onEditPage('CustomerInfo', 'telephoneNumberToWhichTheInterpreterWillCall', 'Summary')}
          />
          <ActionListItem
            key='write_the_content_of_the_matter_to_be_reported_below'
            data-test='writeTheContentOfTheMatterToBeReportedBelowPrebookSummary'
            editable={(props.cloning || props.editMode) || (props.editableFields && props.editableFields.includes('Content'))}
            readOnly={props.editableFields && props.editableFields.includes('Content') ? false : props.readOnly}
            title={i18n('write_the_content_of_the_matter_to_be_reported_below')}
            required={(props.cloning || props.editMode) && !props.contentData}
            subtitle={props.contentData || ''}
            visible={!props.isBillingSummary && isVisible('Content', props.isNoticeInterpretation)}
            onClick={() => props.onEditPage('Content', null, 'Summary')}
          />
          <ActionListItem
            key='reservation_number'
            data-test='reservationNumberPrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={true}
            title={i18n('reservation_number')}
            subtitle={props.orderNumber}
            visible={isVisible('ReservationNumber', props.orderNumber)}
            onClick={() => {}}
          />
          <ActionListItem
            key='cancelled_by'
            data-test='cancelledByPrebookSummary'
            editable={props.cloning || props.editMode}
            readOnly={true}
            title={i18n('cancelled_by')}
            subtitle={props.cancelledByUser?.name}
            visible={isVisible('CancelledBy', props.cancelledByUser?.id)}
            onClick={() => {}}
          />
        </ActionList>
      </ContentBox>
    </StyledContainer>
  );
};

PrebookingSummaryPage.propTypes = {
  callType: PropTypes.string,
  comment: PropTypes.string,
  date: PropTypes.object,
  time: PropTypes.object,
  duration: PropTypes.number,
  invoiceUserGroupName: PropTypes.string,
  customerServiceComments: PropTypes.string,
  presenceBackup: PropTypes.string,
  invoiceRef: PropTypes.string,
  languageName: PropTypes.string,
  targetLanguageName: PropTypes.string,
  dialectSkillName: PropTypes.string,
  genderSkillName: PropTypes.string,
  skillName: PropTypes.string,
  onComment: PropTypes.func
};

export default PrebookingSummaryPage;
