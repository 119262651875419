import React, { useContext, useRef, useState, useEffect, useMemo } from 'react';
import { useTable, useSortBy, useFilters, useRowSelect } from 'react-table';
import moment from 'moment';
import addDurationCapability from 'moment-duration-format';
import amplitude from 'amplitude-js';
import { showError } from '../services/toastService';
import styled from 'styled-components';
import i18n, { langCode } from '../i18n';

import {
  getOrders,
} from '../services/invoiceService';

import ThemeContext from '../contexts/ThemeContext';
import FunctionsContext from '../contexts/FunctionsContext';

import Icon from './Icon';
import IconButton from './IconButton';

import HeaderText from './HeaderText';
import Spinner from './Spinner';

import PrebookTypeIcon from './PrebookTypeIcon';
import StatusBadge from './StatusBadge';
import { SimpleTooltip, CustomTooltip } from './Tooltip';
import {
  BookingsTableContainer,
  BookingTableThead,
  BookingTableTh,
  BookingTableTr,
  BookingTableTd,
  TrText,
  HeaderContainer,
  SortIconContainer,
  BookingsFilterSelect,
} from './basicComponents';

import { parseNoticeMessage, parseNoticeCustomerData } from '../utils/prebooksHelpers';

addDurationCapability(moment);

const StyledInputCheckbox = styled.input`
  width: 17px;
  height: 17px;
`;

const StyledIconContainer = styled.div`
  text-align: center;
`;

const StyledTimeContainer = styled.div`
  text-align: center;
  font-size: ${({ fontSize }) => (fontSize)}px;
  position: relative;
`;

const StyledBookingTableTd = styled(BookingTableTd)`
  &:nth-child(2) {
    padding: 0 8px 0 0;
  }
  &:nth-child(3) {
    text-transform: capitalize;
    width: 120px;
  }
`;

const StyledBadgeIcon = styled(Icon)`
  width: 26px;
  height: 26px;
  background-color: #eeeefe;
  border-radius: 10px;
`;

const StyledDateBlock = styled.span`
  word-spacing: 9999rem;
`;

function SelectColumnFilter({
  column: { filterValue = '', setFilter, preFilteredRows, id }
}) {
  const { layout } = useContext(ThemeContext);

  const options = useMemo(() => {
    const optionsSet = new Set();
    preFilteredRows.forEach(row => {
      optionsSet.add(row.values[id]);
    });
    return [...optionsSet.values()].filter(value => {
      return value && value.length > 0;
    });
  }, [id, preFilteredRows]);

  const callTypeSelectionLocalized = {
    external: i18n('presence_conference'),
    onsite: i18n('presence_on_site'),
    remote: i18n('presence_remote'),
    notice: i18n('presence_notice'),
    data: i18n('call_type_video'),
    call: i18n('call_type_call'),
    prebook: i18n('prebooked'),
    ondemand: i18n('ondemand'),
  };

  return (
    <BookingsFilterSelect
      fontSize={layout.smallFontSize}
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">{i18n('all')}</option>
      {options.map((option, i) => {
        if (id === 'callType' || id === 'type') {
          return (
            <option key={i} value={option}>
              {callTypeSelectionLocalized[option] || option}
            </option>
          );
        }
        return (
          <option key={i} value={option}>
            {option}
          </option>
        );
      })}
    </BookingsFilterSelect>
  );
}

const getClassName = (columnId) => {
  if (columnId === 'type') {
    return 'type';
  }
  if (columnId === 'invoiceRef') {
    return 'invoiceRef';
  }
  if (columnId === 'invoiceRecipientRef') {
    return 'invoiceRecipientRef';
  }
  if (columnId === 'customerName') {
    return 'customerName';
  }
  if (columnId === 'invoiceUserGroup') {
    return 'invoiceUserGroup';
  }
  return '';
};

const getPrebookStatus = (presence, interpreter, durationActual) => {
  if (presence === 'notice') {
    if (durationActual > 0) {
      return 'delivered';
    }
    if (durationActual === 0) {
      return 'not_able_to_reach';
    }
  }
  return (interpreter) ? 'confirmed' : 'pending';
};

const isRequiredFieldsFilled = (chatroomOrPrebook) => {
  if (!chatroomOrPrebook.invoiceUserGroup?.id) {
    return false;
  }
  if (chatroomOrPrebook.customFields && chatroomOrPrebook.customFields.length > 0) {
    const notFilled = chatroomOrPrebook.customFields.reduce((memo, customField) => {
      if (customField.required && !customField.value) {
        memo.push(customField);
      }
      return memo;
    }, []);
    if (notFilled.length > 0) {
      return false;
    }
  }
  return true;
};

const preparePrebookings = (prebookings, chatrooms) => {
  const preparedChatrooms = chatrooms.map((chatroom) => ({
    id: chatroom.id,
    date: new Date(chatroom.createdAt),
    callType: (chatroom.callType === 'data') ? 'external' : chatroom.callType,
    language: chatroom.interpretedLanguage.name,
    status: (chatroom && chatroom.interpreter) ? 'confirmed' : 'pending',
    durationEstimated: chatroom.billableDuration,
    interpreter: chatroom.interpreter,
    orderNumber: chatroom.orderNumber,
    customerId: chatroom?.customer?.id,
    customerName: chatroom?.customer?.name ? chatroom.customer.name : '',
    invoiceRef: chatroom.invoiceRef || '',
    invoiceUserGroup: (chatroom.invoiceUserGroup && chatroom.invoiceUserGroup.name) ? chatroom.invoiceUserGroup.name : '',
    invoiceRecipientRef: chatroom.invoiceRecipientRef ? chatroom.invoiceRecipientRef : '',
    hotlinePhoneNumber: null,
    type: 'ondemand',
    notAvailableForBatchConfirmation: !isRequiredFieldsFilled(chatroom),
  }));
  const preparedPrebookings = prebookings.map((booking) => {
    return {
      id: booking.id,
      date: new Date(booking.interpretationDate),
      callType: (booking.presence === 'onsite' || booking.presence === 'notice' || (booking.presence === 'external' && booking.callType === 'data')) ? booking.presence : booking.callType,
      language: booking.interpretedLanguage.name,
      status: getPrebookStatus(booking.presence, booking?.interpreter, booking.durationActual),
      durationEstimated: booking.durationEstimated,
      leewayBefore: booking.leewayBefore,
      interpreter: booking.interpreter,
      orderNumber: booking.orderNumber,
      customerId: booking.customer?.id,
      customerName: (booking.customer && booking.customer.name) ? booking.customer.name : '',
      invoiceRef: booking.invoiceRef || '',
      invoiceUserGroup: (booking.invoiceUserGroup && booking.invoiceUserGroup.name) ? booking.invoiceUserGroup.name : '',
      invoiceRecipientRef: booking.invoiceRecipientRef ? booking.invoiceRecipientRef : '',
      hotlinePhoneNumber: booking.hotlinePhoneNumber,
      type: 'prebook',
      notAvailableForBatchConfirmation: !isRequiredFieldsFilled(booking),
    };
  });
  return [
    ...preparedPrebookings,
    ...preparedChatrooms
  ].sort((a, b) => new Date(b.date) - new Date(a.date));
};

const IndeterminateCheckbox = React.forwardRef(
  ({
    indeterminate,
    ...rest
  }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <StyledInputCheckbox type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const parseMessagesIfNeeded = (prebookings) => {
  return prebookings.map((booking) => {
    if (booking.presence === 'notice') {
      const parsedNoticeMessage = parseNoticeMessage(booking.messageToInterpreter);
      const parsedNoticeCustomerData = parseNoticeCustomerData(booking.invoiceRef);

      return {
        ...booking,
        customerData: parsedNoticeCustomerData.customerData,
        customerDataBirthday: parsedNoticeCustomerData.customerBirthday,
        alternativeContactPerson: parsedNoticeMessage.alternativeContactPerson,
        mainPhoneNumber: parsedNoticeMessage.phoneNumbers[0],
        phoneNumbers: parsedNoticeMessage.phoneNumbers?.slice(1)?.join(' '),
        messageToCustomerService: booking.comments,
        contentData: parsedNoticeMessage.contentData,
      };
    }
    return booking;
  });
};

const BookingsTable = ({ openPreview, active, setItemsForVerify }) => {
  moment.locale(langCode);

  const { colors, layout } = useContext(ThemeContext);
  const { sessionExpired } = useContext(FunctionsContext);

  const _isMounted = useRef(true); // Initial value _isMounted = true

  useEffect(() => {
    return () => { // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, []);

  const [prebookings, setPrebookings] = useState([]);
  const [chatRooms, setChatRooms] = useState([]);
  const [indicator, setIndicator] = useState(false);
  const [updateIndicator, setUpdateIndicator] = useState(false);

  const extendWithType = (prebooks, type) => {
    return prebooks.map((prebook) => ({
      ...prebook,
      type: type,
    }));
  };

  const getPrebookings = async () => {
    setIndicator(true);
    try {
      const result = await getOrders();
      if (_isMounted.current) {
        setPrebookings(extendWithType(parseMessagesIfNeeded(result.prebookings), 'prebook'));
        setChatRooms(extendWithType(result.chatRooms, 'ondemand'));
        setIndicator(false);
        setUpdateIndicator(false);
        document.dispatchEvent(new CustomEvent('updateInvoiceCount', {
          detail: {
            count: (result.prebookings.length || 0) + (result.chatRooms.length || 0)
          }
        }));
      }
    } catch (e) {
      console.log('Failed to get orders.', e);

      if (e.response !== undefined && e.response.status === 400) {
        amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
        if (_isMounted.current) {
          showError(i18n('error500'));
        }
      } else if (e.message === 'Session expired') {
        sessionExpired();
      } else {
        amplitude.getInstance().logEvent('Accept request failed', { 'HTTP status code': e.response ? String(e.response.status) : '' });
        if (_isMounted.current) {
          showError(i18n('error500'));
        }
      }
      if (_isMounted.current) {
        setIndicator(false);
      }
    }
  };

  useEffect(() => {
    const func = async () => {
      setUpdateIndicator(true);
      await getPrebookings();
    };
    document.addEventListener('reloadBillingPrebooksTable', func);
    return () => {
      document.removeEventListener('reloadBillingPrebooksTable', func);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (active !== false) {
      getPrebookings();
    }
  }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

  const openPreviewModal = (prebook, editable) => {
    const prebookForPreview = prebookings.find((aPrebook) => aPrebook.id === prebook.id) || chatRooms.find((chatRoom) => chatRoom.id === prebook.id);
    if (prebookForPreview.type === 'ondemand') {
      openPreview({
        ...prebookForPreview,
        presence: 'external',
        type: 'ondemand',
      }, editable);
    } else {
      openPreview({
        ...prebookForPreview,
        type: 'prebook',
      }, editable);
    }
  };

  const isExistReservationNumber = (prebookingsArray, chatRoomsArray) => (
    prebookingsArray.some((elem) => elem.orderNumber) || chatRoomsArray.some((elem) => elem.orderNumber)
  );

  const columns = useMemo(() => {
    const titles = [
      {
        Header: () => (
          <StyledBadgeIcon
            icon={'alarm'}
            iconLib="material"
            iconSize="18"
            iconColor={colors.webDarkBlue}
          />
        ),
        accessor: 'type',
        Cell: ({ cell: { row }}) => {
          return row?.original?.type ? (
            <CustomTooltip
              direction="right"
              text={row.original.type === 'prebook' ? i18n('prebooked') : i18n('ondemand')}
            >
              <StyledBadgeIcon
                icon={row.original.type === 'prebook' ? 'alarm' : 'alarm_off'}
                iconLib="material"
                iconSize="18"
                iconColor={colors.webDarkBlue}
                data-tip={i18n('call_type_video')}
                data-event="hover"
              />
            </CustomTooltip>
          ) : null;
        },
        Filter: SelectColumnFilter,
        filter: 'includes'
      },
      {
        Header: i18n('time'),
        accessor: 'date',
        Cell: ({ cell: { value }}) => {
          const date = moment(value);
          return (
            <StyledTimeContainer
              fontSize={layout.smallFontSize}
            >
              <StyledDateBlock>
                {date.format('DD MMMM HH:mm')}
              </StyledDateBlock>
            </StyledTimeContainer>
          );
        },
        sortType: 'datetime'
      },
      {
        Header: i18n('language_title'),
        accessor: 'language',
        sortType: 'basic'
      },
      {
        Header: i18n('status'),
        accessor: 'status',
        sortType: 'basic',
        Cell: ({ cell: { value } }) => (
          <StatusBadge
            status={value}
          />
        )
      },
      {
        Header: i18n('prebooking_duration'),
        accessor: 'durationEstimated',
        sortType: 'basic',
        Cell: ({ cell: { value }}) => {
          return moment.duration(value, 'seconds').format('hh:mm', {
            trim: false
          });
        },
      },
      {
        Header: i18n('call_type_title'),
        accessor: 'callType',
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ cell: { value }}) => (
          <PrebookTypeIcon
            type={value}
          />
        ),
      },
      {
        Header: i18n('interpreter_phone_number'),
        accessor: 'hotlinePhoneNumber',
        sortType: 'basic',
      }
    ];

    titles.push({
      Header: i18n('customer'),
      accessor: 'customerName',
      Filter: SelectColumnFilter,
      filter: 'includes',
      sortType: 'basic',
    });

    if (isExistReservationNumber(prebookings, chatRooms)) {
      titles.push({
        Header: i18n('reservation_number'),
        accessor: 'orderNumber',
        sortType: 'basic'
      });
    }

    titles.push({
      Header: i18n('invoice_user_group'),
      accessor: 'invoiceUserGroup',
      Filter: SelectColumnFilter,
      filter: 'includes',
      sortType: 'basic',
    });

    titles.push({
      Header: i18n('customerInvoiceNotes'),
      accessor: 'invoiceRef',
      Filter: SelectColumnFilter,
      filter: 'includes',
      sortType: 'basic',
      Cell: ({ cell: { value, row }}) => {
        if (row.original.callType === 'notice') {
          return '';
        }
        return value;
      },
    });

    titles.push({
      Header: i18n('alternative_cost_center'),
      accessor: 'invoiceRecipientRef',
      Filter: SelectColumnFilter,
      filter: 'includes',
      sortType: 'basic',
    });

    titles.push({
      Header: '',
      accessor: 'editButton',
      Cell: (props) => {
        return (
          <StyledIconContainer>
            <CustomTooltip
              direction="top"
              text={i18n('edit')}
            >
              <IconButton
                icon="edit"
                iconLib="material"
                iconColor={colors.webDarkBlue}
                hoverColor={colors.tulkaMainColor}
                onClick={() => {
                  if (props && props.row && props.row.original && props.row.original.id) {
                    openPreviewModal(props.row.original, true);
                  }
                }}
              />
            </CustomTooltip>
          </StyledIconContainer>
        );
      },
    });

    titles.push({
      Header: '',
      accessor: 'previewButton',
      Cell: (props) => {
        return (
          <StyledIconContainer>
            <CustomTooltip
              direction="top"
              text={i18n('preview')}
            >
              <IconButton
                icon="eye"
                iconLib="fontAwesome"
                iconColor={colors.webDarkBlue}
                hoverColor={colors.tulkaMainColor}
                onClick={() => {
                  if (props && props.row && props.row.original && props.row.original.id) {
                    openPreviewModal(props.row.original, false);
                  }
                }}
              />
            </CustomTooltip>
          </StyledIconContainer>
        );
      }
    });

    titles.push({
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div>
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: ({ row }) => {
        if (row?.original?.notAvailableForBatchConfirmation) {
          const { checked, ...rest } = row.getToggleRowSelectedProps();
          return (
            <div>
              <CustomTooltip
                direction="top"
                text={i18n('this_invoice_needs_additional_information')}
              >
                <IndeterminateCheckbox
                  {...rest}
                  {...(!row?.original?.notAvailableForBatchConfirmation ? {
                    checked
                  } : {})}
                  disabled={row?.original?.notAvailableForBatchConfirmation}
                />
              </CustomTooltip>
            </div>
          );
        }
        return (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
            />
          </div>
        );
      }
    });

    return titles;
  }, [prebookings, chatRooms, colors, layout]); // eslint-disable-line react-hooks/exhaustive-deps

  const data = useMemo(() => {
    return preparePrebookings(prebookings, chatRooms) || [];
  }, [ prebookings, chatRooms ]);

  const tableInstance = useTable({ columns, data, autoResetFilters: false }, useFilters, useSortBy, useRowSelect);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setAllFilters,
  } = tableInstance;

  useEffect(() => {
    if ((rows && rows.length === 0) && (data && data.length > 0) && state.filters.length > 0) {
      setAllFilters([]);
    }
  }, [data, rows, state.filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const itemsObj = rows.reduce((memo, row) => {
      if (state.selectedRowIds[row.id] && !row?.original?.notAvailableForBatchConfirmation) {
        return memo.concat({
          id: row.original.id,
          type: row.original.type
        });
      }
      return memo;
    }, []);
    setItemsForVerify(itemsObj);
  }, [rows, state.selectedRowIds]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <BookingsTableContainer
        fontSize={layout.smallFontSize}
        {...getTableProps()}
      >
        <BookingTableThead>
          {headerGroups.map(headerGroup => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()} valign="top">
                {headerGroup.headers.map((column, index) => {
                  return (
                    <BookingTableTh
                      lang={langCode}
                      className={getClassName(column.id)}
                      key={index}
                    >
                      <TrText
                        fontSize={layout.smallFontSize}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                        {column.id !== 'selection' && column.id !== 'previewButton' && column.id !== 'editButton' && column.id !== 'type' ? (
                          <SortIconContainer>
                            <Icon
                              icon='arrows-v'
                              iconLib='fontAwesome'
                              iconColor={colors.webDarkBlue}
                              iconSize={12}
                              withoutContainer={true}
                            />
                            <Icon
                              icon='sort'
                              iconLib='material'
                              iconColor={colors.webDarkBlue}
                              iconSize={16}
                              withoutContainer={true}
                            />
                          </SortIconContainer>
                        ): null}
                      </TrText>
                      <div>
                        {column.Filter ? column.render('Filter') : null}
                      </div>
                    </BookingTableTh>
                  );
                })}
              </tr>
            );
          })}
        </BookingTableThead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <BookingTableTr
                {...row.getRowProps()}
                onClick={(event) => {
                  if (event.target.nodeName.toLowerCase() !== 'input' && event.target.nodeName.toLowerCase() !== 'i' && event.target.nodeName.toLowerCase() !== 'button' && row && row.original && row.original.id) {
                    openPreviewModal(row.original, true);
                  }
                }}
              >
                {row.cells.map(cell => {
                  return (
                    <StyledBookingTableTd
                      className={getClassName(cell.column.id)}
                      {...cell.getCellProps()}
                    >
                      {
                        cell.column.id !== 'type' && getClassName(cell.column.id) ? (
                          <SimpleTooltip
                            direction="top"
                            text={cell.render('Cell')}
                          >
                            {cell.render('Cell')}
                          </SimpleTooltip>
                        ) : cell.render('Cell')
                      }
                    </StyledBookingTableTd>
                  );
                })}
              </BookingTableTr>
            );
          })}
        </tbody>
      </BookingsTableContainer>
      {updateIndicator ? (
        <Spinner
          overlay
        />
      ): null}
      {(rows.length === 0) && (
        <HeaderContainer>
          {indicator ? (
            <Spinner/>
          ): (
            <HeaderText
              text={i18n('no_invoices')}
              fontSize={layout.headerFontSize}
            />
          )}
        </HeaderContainer>
      )}
    </div>
  );
};

export default BookingsTable;
